import {
  PackageInstanceCard,
  PackageInstanceCardContentTypeEnum,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
} from "@practice/sdk";
import { DateTime } from "luxon";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageTimeType } from "@lib/data/schemas/packages";
import { getCurrentCycle } from "@lib/models/package-instances/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import { getTotalSessionsInMinutes } from "@components/Package/utils";

/**
 * Formats the reset date
 * */
export const formatResetDate = (resetDate: any) =>
  moment(getNormalizedDate(resetDate)).format("MMM DD");

export const getRecurringCycleTotal = (
  packageInstance?: PackageInstanceCard | PackageInstancePublic
) => {
  const currentCycle = getCurrentCycle(packageInstance);
  if (!currentCycle) return packageInstance?.totalAvailable ?? 0;

  return currentCycle.totalAvailable;
};

export const getRecurringCycleTotalBooked = (
  packageInstance: PackageInstanceCard | PackageInstancePublic
) => {
  const currentCycle = getCurrentCycle(packageInstance);
  if (!currentCycle) return packageInstance.totalBooked;

  return currentCycle.totalBooked;
};

export const getTotalSessions = (
  pi: PackageInstanceCard | PackageInstancePublic,
  currentCycleOnly?: boolean
) => {
  // Return total sessions and only calculate if current cycle only
  if (!currentCycleOnly && pi?.totalAvailable) return pi.totalAvailable;

  if (!pi?.frequency) return pi?.totalAvailable ?? 0;
  if (pi?.isRollover) pi.totalAvailable ?? 0;
  if (currentCycleOnly)
    return getRecurringCycleTotal(pi) ?? pi?.totalAvailable ?? 0;
  return pi?.totalAvailable ?? 0;
};

const getTotalBooked = (
  pi: PackageInstanceCard | PackageInstancePublic,
  currentCycleOnly?: boolean
) => {
  // Return total sessions and only calculate if current cycle only
  if (!currentCycleOnly && pi.totalBooked) return pi.totalBooked;

  if (!pi?.frequency) return pi.totalBooked ?? 0;
  if (pi?.isRollover) pi.totalBooked ?? 0;
  if (currentCycleOnly)
    return getRecurringCycleTotalBooked(pi) ?? pi?.totalBooked;
  return pi?.totalBooked ?? 0;
};

/**
 * Get the total of appointment remaining given a package instance
 * */
export const getRemainingAppointmentsAcrossSchedulers = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  currentCycleOnly?: boolean
): number => {
  const totalSessions = getTotalSessions(packageInstance, currentCycleOnly);

  const completed = getTotalBooked(packageInstance, currentCycleOnly);
  const remaining = totalSessions - completed;
  return Math.max(0, remaining);
};

type PackageContentType = `${PackageInstanceCardContentTypeEnum}`;

/**
 * Returns some helper data from a package instance
 * */
type GetPackageInstanceHelper = (
  packageInstance: PackageInstanceCard,
  currentCycleOnly?: boolean,
  fromCycle?: number
) => {
  contentType: PackageContentType;
  packageType: string;
  totalSessions: number;
  distributeSessions: boolean;
  timeType: PackageTimeType | null;
  isContentTypeSession: boolean;
  isContentTypeTime: boolean;
  isPackageTypeRecurring: boolean;
  isUsagePackage: boolean;
  isRollOver: boolean;
  isPackageLocked: boolean;
  isSubscriptionUBP: boolean;
};

export const getPackageInstanceHelper: GetPackageInstanceHelper = (
  packageInstance: PackageInstanceCard | PackageInstancePublic | undefined,
  currentCycleOnly?: boolean
) => {
  const {
    contentType = "sessions",
    packageType = "one-time",
    timeType = "hours",
  } = packageInstance ?? {};

  const isContentTypeSession = contentType === "sessions";
  const isContentTypeTime = contentType === "time";
  const isPackageTypeRecurring = packageType === "recurring";
  const isUsagePackage = packageType === "usage";
  const isRollOver = packageInstance?.isRollover ?? false;
  const isPackageLocked = packageInstance?.isLocked ?? false;
  const isSubscriptionUBP =
    isUsagePackage &&
    packageInstance?.usageInvoicing?.method === "subscription";

  const packageInstanceTotalApptsAvailable =
    packageInstance?.totalAvailable || 0;
  const recurringTotalAvailableSessions =
    currentCycleOnly && !isRollOver
      ? packageInstance?.cycles?.find((item) => item.isCurrent)
          ?.totalAvailable ?? 0
      : packageInstanceTotalApptsAvailable;

  const totalSessions = isPackageTypeRecurring
    ? recurringTotalAvailableSessions
    : packageInstanceTotalApptsAvailable;

  const distributeSessions = isPackageTypeRecurring
    ? true
    : !!packageInstance?.distributeSessions;

  return {
    contentType,
    packageType,
    totalSessions,
    distributeSessions,
    timeType,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isRollOver,
    isPackageLocked,
    isSubscriptionUBP,
  };
};

/**
 * Returns data to identify wheter the package instance is from distribute
 * across schedulers
 * */
type GetPackageInstanceInfo = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  currentCycleOnly?: boolean,
  fromCycle?: PackageInstanceCardCyclesInner | null
) => {
  distributeSessions: boolean;
  totalSessions: number;
  contentType: PackageContentType;
  totalRemainingAcrossSchedulers: number;
  totalAppointmentsRemainingInMinutes: number;
  totalAppointmentsConsumedInMinutes: number;
  totalSessionsConsumedInTotalAppointments: number;
  totalSessionsInMinutes: number;
  isTotalRemainingAcrossSchedulersComplete: boolean;
  isContentTypeSession: boolean;
  isContentTypeTime: boolean;
  isPackageTypeRecurring: boolean;
  isUsagePackage: boolean;
  isRollOver: boolean;
  showSchedulerRemaining: boolean;
  formattedTimeConsumed: string;
  formattedRemaining: string;
  isCompleted: boolean;
  isPackageLocked: boolean;
  isSubscriptionUBP: boolean;
};

export const isPackageInstancePaused = (
  packageInstance: PackageInstanceCard | PackageInstancePublic
) => {
  return !!packageInstance?.pausedOn;
};

export const getPackageInstanceInfo: GetPackageInstanceInfo = (
  packageInstance,
  currentCycleOnly = false,
  fromCycle
) => {
  const {
    contentType,
    totalSessions,
    distributeSessions,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isRollOver,
    isPackageLocked,
    isSubscriptionUBP,
  } = getPackageInstanceHelper(
    packageInstance as PackageInstanceCard,
    currentCycleOnly
  );

  const cycles = packageInstance?.cycles || [];
  const fromCycleIndex = cycles.findIndex((cycle) => cycle === fromCycle);
  const rolloverConsumed = isRollOver
    ? cycles.reduce((agg, cycle, index) => {
        return index <= fromCycleIndex ? agg + cycle.totalBooked : agg;
      }, 0)
    : 0;
  const currentCycle = getCurrentCycle(packageInstance);

  const totalRemainingAcrossSchedulers =
    getRemainingAppointmentsAcrossSchedulers(
      packageInstance,
      isPackageTypeRecurring ? currentCycleOnly : false
    );

  const showSchedulerRemaining =
    !distributeSessions && isContentTypeSession && !isUsagePackage;

  const totalSessionsInMinutes = getTotalSessionsInMinutes(
    totalSessions,
    packageInstance?.timeType || "hours"
  );

  const notFromCycleProp =
    currentCycleOnly && currentCycle && !isRollOver
      ? currentCycle.totalBooked
      : packageInstance.totalBooked;
  const totalSessionsConsumedInTotalAppointments = fromCycle
    ? isRollOver
      ? rolloverConsumed
      : fromCycle.totalBooked ?? 0
    : notFromCycleProp;

  const totalAppointmentsConsumedInMinutes = getTotalSessionsInMinutes(
    totalSessionsConsumedInTotalAppointments,
    packageInstance?.timeType || "hours"
  );

  const totalAppointmentsRemainingInMinutes = Math.round(
    totalSessionsInMinutes - totalAppointmentsConsumedInMinutes
  );

  const isTimeMinutesBased =
    isContentTypeTime && packageInstance?.timeType === "minutes";

  const packageItems = (packageInstance?.items ||
    []) as PackageInstanceCard["items"];
  const totalSessionsFromSchedulersQuantity = packageItems?.reduce(
    (acc, scheduler) => {
      const { quantity = 0 } = scheduler;
      return acc + quantity;
    },
    0
  );

  const isTotalRemainingAcrossSchedulersComplete = isContentTypeSession
    ? totalRemainingAcrossSchedulers === 0
    : totalAppointmentsRemainingInMinutes === 0;

  const isCompleted = packageInstance.status === "completed";

  // formatters
  const formattedTimeConsumed =
    isTimeMinutesBased || totalAppointmentsConsumedInMinutes < 60
      ? formatInMinutes(totalAppointmentsConsumedInMinutes)
      : formatDuration(totalAppointmentsConsumedInMinutes);

  const formattedTimeRemaining =
    isTimeMinutesBased || totalAppointmentsRemainingInMinutes < 60
      ? formatInMinutes(totalAppointmentsRemainingInMinutes)
      : formatDuration(totalAppointmentsRemainingInMinutes);

  const formattedRemaining = isContentTypeSession
    ? pluralHelper(totalRemainingAcrossSchedulers, "appointment")
    : formattedTimeRemaining;

  return {
    distributeSessions,
    totalSessions: distributeSessions
      ? totalSessions
      : totalSessionsFromSchedulersQuantity,
    contentType,
    totalRemainingAcrossSchedulers,
    totalAppointmentsRemainingInMinutes,
    totalAppointmentsConsumedInMinutes,
    totalSessionsConsumedInTotalAppointments,
    totalSessionsInMinutes,
    isTotalRemainingAcrossSchedulersComplete,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isCompleted,
    isRollOver,
    showSchedulerRemaining,
    formattedTimeConsumed: formattedTimeConsumed || "0",
    formattedRemaining,
    isPackageLocked,
    isSubscriptionUBP,
  };
};

/**
 * Get the total appointments consumed in minutes or seconds
 * */
export const getTotalAppointmentsConsumed = (
  appointments: AppointmentType[],
  format: "minutes" | "seconds" = "minutes"
) => {
  const getDateTime = (date: any) =>
    date?.seconds ? DateTime.fromSeconds(date.seconds) : DateTime.fromISO(date);

  const result = appointments?.reduce((acc, item) => {
    const { start, end } = item;
    const startDate = getDateTime(start);
    const endDate = getDateTime(end);
    const diff = endDate.diff(startDate, format)[format];
    return acc + diff;
  }, 0);

  return result;
};

/**
 * Formats a duration in minutes to a human readable format
 * */
export const formatDuration = (value: number) => {
  const hours = value / 60;
  const calculatedStringValue = hours.toString();
  const floatedHours = parseFloat(calculatedStringValue).toFixed(2);
  const splitted = floatedHours.split(".");
  let formattedFloatHours;

  if (splitted[1] === "0") {
    formattedFloatHours = splitted[0];
  } else {
    formattedFloatHours = parseFloat(floatedHours);
  }

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += `${formattedFloatHours} hours`;
    if (hours === 1) {
      formattedDuration = formattedDuration.replace("hours", "hour");
    }
  }

  return formattedDuration;
};

/**
 * Formats a duration in minutes to a human readable format
 * */
export const formatInMinutes = (value: number) => `${value} minutes`;
