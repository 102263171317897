import { useEffect, useMemo, useState } from "react";
import {
  getContact,
  getContacts,
  getContactsNumbers,
  getHasAnyClient,
  matchClients,
} from "api-services/definitions/contacts";
import { useApi, useApiGet } from "api-services/endpoints";
import { KeyedMutator } from "swr";
import { objectInputType, ZodAny, ZodOptional, ZodString } from "zod";

import { useAuth } from "@contexts/auth";
import { filterAttendesNoCancelled, mapAttendesEmail } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import {
  ClientStatusEnumType,
  ClientType,
  ClientTypeEnumType,
} from "@lib/data/schemas/client";

export const useMatchingContacts = (
  emails: string[]
): {
  data?: ClientType[];
  loading: boolean;
} => {
  const [data, setData] = useState<ClientType[] | undefined>(undefined);
  const { oid } = useAuth();
  const { apiCall, loading } = useApi(matchClients);

  useEffect(() => {
    if (!emails?.length || !oid) {
      setData([]);
      return;
    }

    apiCall({ orgId: oid }, { emails }, undefined).then((res) => {
      setData(res?.data as ClientType[]);
    });
  }, [JSON.stringify(emails)]);

  return {
    data,
    loading: loading,
  };
};

export const useGetEventContacts = (
  appointment: AppointmentType
): {
  data?: ClientType[];
  loading: boolean;
} => {
  const attendees = appointment.gcal?.event?.attendees || [];
  const filteredAttendees = attendees.filter(filterAttendesNoCancelled);
  const attendeesEmails = filteredAttendees.map(mapAttendesEmail) || [];

  return useMatchingContacts(attendeesEmails);
};

export const useContacts = (
  withAssigneeIds?: boolean
): {
  data?: Array<ClientType | (ClientType & { assigneeId?: string })>;
  contactsMap?: Map<string, ClientType>;
  active?: Array<ClientType | (ClientType & { assigneeId?: string })>;
  loading: boolean;
  mutate: KeyedMutator<{
    data: objectInputType<
      {
        id: ZodString;
        assigneeId: ZodOptional<ZodString>;
      },
      ZodAny,
      "strip"
    >[];
    cursorId?: string | undefined;
  }>;
} => {
  const { oid } = useAuth();
  const {
    data: apiData,
    loading,
    mutate,
  } = useApiGet(
    getContacts,
    { orgId: oid! },
    {},
    {
      dedupingInterval: 60000,
    }
  );

  const response = useMemo(() => {
    const contacts = apiData?.data as ClientType[] | undefined;
    if (!contacts)
      return {
        data: undefined,
        active: undefined,
      };
    const data = contacts
      .filter((contact) => contact.status !== "merged")
      .filter((contact) => contact.clientType === "individual");

    const active = data?.filter(
      (contact) => contact.status !== "archived" && contact.status !== "deleted"
    );

    const withAssignees = withAssigneeIds
      ? data?.map((contact) => {
          const assigneeId = contacts?.find((c) => c.id === contact.id)
            ?.assigneeId;
          return { ...contact, assigneeId };
        })
      : undefined;

    return {
      data: withAssigneeIds ? withAssignees : data,
      active,
    };
  }, [apiData, withAssigneeIds]);

  const contactsMap = useMemo(() => {
    if (!response?.data) return undefined;
    const contacts = response?.data as ClientType[] | undefined;
    return new Map(contacts?.map((contact) => [contact.id, contact]));
  }, [response?.data]);

  return {
    ...response,
    contactsMap,
    loading: loading,
    mutate,
  };
};

export const useContactsNumbers = (): {
  loading: boolean;
  total?: number;
  active?: number;
} => {
  const { oid } = useAuth();
  const { data: apiData, loading } = useApiGet(
    getContactsNumbers,
    { orgId: oid! },
    undefined,
    {
      dedupingInterval: 60000,
    }
  );

  return {
    ...apiData,
    loading: loading,
  };
};

export const useHasAnyClient = (
  status?: ClientStatusEnumType,
  clientType?: ClientTypeEnumType
): {
  loading: boolean;
  hasAnyClient?: boolean;
} => {
  const { oid } = useAuth();
  const { data: apiData, loading } = useApiGet(
    getHasAnyClient,
    { orgId: oid! },
    { ...(status && { status }), ...(clientType && { clientType }) },
    {
      dedupingInterval: 60000,
    }
  );

  return {
    hasAnyClient: apiData,
    loading: loading,
  };
};

export const useContact = (contactId?: string | null) => {
  const { oid } = useAuth();
  const { parentContact } = useParentContact(contactId);

  const {
    data: apiData,
    isLoading,
    loading,
    mutate,
    error,
  } = useApiGet(
    oid && contactId ? getContact : undefined,
    oid && contactId ? { orgId: oid, contactId } : undefined,
    undefined,
    {
      dedupingInterval: 60000,
    }
  );

  const contact = apiData?.data as ClientType | undefined;

  return {
    isLoading,
    /** @deprecated Returns [true] if [contact] is undefined which may not be what you need. Use isLoading instead. */
    loading,
    contact,
    parentContact,
    mutate,
    error,
  };
};

export const useParentContact = (contactId: string | undefined | null) => {
  const { oid } = useAuth();

  const { data: companies, loading: loadingCompanies } = useApiGet(
    oid && contactId ? getContacts : undefined,
    oid && contactId ? { orgId: oid } : undefined,
    { clientType: "company" },
    {
      dedupingInterval: 60000,
    }
  );

  const { data: families, loading: loadingFamilies } = useApiGet(
    oid && contactId ? getContacts : undefined,
    oid && contactId ? { orgId: oid } : undefined,
    { clientType: "family" },
    {
      dedupingInterval: 60000,
    }
  );

  const parentContact = useMemo(() => {
    const potentialParents = (companies?.data ?? []).concat(
      families?.data ?? []
    ) as ClientType[];
    return potentialParents
      ?.filter((client) => client.status !== "deleted")
      .find(
        (client) => client.members?.some((member) => member.id === contactId)
      );
  }, [companies, families, contactId]);

  return {
    loading: loadingCompanies || loadingFamilies,
    parentContact,
  };
};
