import { PackageInstanceApi, PackageInstanceCard } from "@practice/sdk";
import { packageSchedulersGet } from "api-services/definitions/package-instances";
import { useApiGetMutate } from "api-services/endpoints";
import useSWR, { useSWRConfig } from "swr";

import { useAuth } from "@contexts/auth";
import { useSDKApi } from "@hooks/use-sdk-api";

type usePackageInstancesType = (
  coachUserId?: string,
  clientId?: string,
  parentClientId?: string
) => {
  packageInstances?: PackageInstanceCard[];
  loading: boolean;
  mutate: () => void;
};

const usePackageInstances: usePackageInstancesType = (
  coachUserId,
  clientId,
  parentClientId
) => {
  const packageInstanceApi = useSDKApi(PackageInstanceApi);

  const swrData = useSWR(
    `organizations/${coachUserId}/clients/${clientId}/package-instances?clientParentId=${parentClientId}`,
    async () => {
      if (!coachUserId || !clientId) return;
      return packageInstanceApi.getPackageInstanceCards({
        organizationId: coachUserId,
        clientId,
        clientParentId: parentClientId,
      });
    },
    { revalidateOnFocus: false }
  );

  return {
    loading: !swrData.data,
    packageInstances: swrData.data,
    mutate: swrData.mutate,
  };
};

export const usePackageInstancesMutate = (clientId?: string) => {
  const { oid } = useAuth();
  const { mutate } = useSWRConfig();
  const path =
    oid && clientId
      ? `organizations/${oid}/clients/${clientId}/package-instances`
      : null;

  const mutatePackageSchedulers = useApiGetMutate(
    oid && clientId ? packageSchedulersGet : undefined,
    {
      userId: oid!,
      clientId: clientId!,
    },
    undefined,
    {
      ignoreQuery: true,
    }
  );

  return () => {
    mutatePackageSchedulers();
    return mutate(path);
  };
};

export default usePackageInstances;
