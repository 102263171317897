import { useCallback, useMemo } from "react";
import {
  FilterBySpec,
  FilterBySpecOneOfOperatorEnum,
  SearchApi,
  SearchRequestCollectionEnum,
  SortBySpecDirectionEnum,
} from "@practice/sdk";
import { flatten } from "lodash";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

import { useSDKApi } from "./use-sdk-api";

// This simplifies the usage of the enums
// we can now use "contacts" instead of SearchRequestCollectionEnum.Contacts
type Collection = `${SearchRequestCollectionEnum}`;
type SortByDirection = `${SortBySpecDirectionEnum}`;
type FilterByOperator = `${FilterBySpecOneOfOperatorEnum}`;

type ContactSearchResult = {
  id: string;
  name: string;
  emails: string[];
  latestActivity?: {
    date: number;
    name: string;
  };
  labels: { id: string; title: string }[];
};

type CommonSearchParams = {
  query?: string;
  queryBy?: string[];
  filterBy?: {
    field: string;
    value: string | Array<string>;
    operator?: FilterByOperator;
    refCollection?: Collection;
  }[];
  sortBy?: { field: string; direction: SortByDirection }[];
};

export type SearchParams = CommonSearchParams & {
  limit?: number;
  offset?: number;
};

export type InfiniteSearchParams = CommonSearchParams & {
  pageSize: number;
};

export const useContactSearch = (
  organizationId: string | undefined,
  searchParams: SearchParams
) => {
  return useSearch<ContactSearchResult>(
    organizationId,
    "contacts",
    searchParams
  );
};

const useSearch = <T extends { id: string }>(
  organizationId: string | undefined,
  collection: Collection,
  searchParams: SearchParams
) => {
  const searchApi = useSDKApi(SearchApi);

  // SWR allows for cache keys to be full-blown objects
  // We're building our key based on the search parameters
  const swrKey = {
    method: "search",
    organizationId,
    collection,
    ...searchParams,
  };

  const { data, isLoading } = useSWR(swrKey, async () => {
    if (!organizationId) return;

    const res = await searchApi.search({
      searchRequest: {
        ...searchParams,
        organizationId,
        collection: collection as SearchRequestCollectionEnum,
        filterBy: searchParams.filterBy?.map(
          (filterBy) =>
            ({
              ...filterBy,
              operator: filterBy.operator as FilterByOperator,
            }) as FilterBySpec
        ),
        sortBy: searchParams.sortBy?.map((sortBy) => ({
          ...sortBy,
          direction: sortBy.direction as SortBySpecDirectionEnum,
        })),
      },
    });
    return {
      hits: res.result.hits.map((hit) => ({
        ...hit,
        document: hit.document as T,
      })),
    };
  });

  return { data, isLoading };
};

export const useContactSearchInfinite = <R = ContactSearchResult>(
  organizationId: string | undefined,
  searchParams: InfiniteSearchParams,
  transform?: (document: ContactSearchResult) => R
) => {
  return useSearchInfinite<ContactSearchResult, R>(
    organizationId,
    "contacts",
    searchParams,
    transform
  );
};

export const useSearchInfinite = <T extends { id: string }, R = T>(
  organizationId: string | undefined,
  collection: Collection,
  searchParams: InfiniteSearchParams,
  transform?: (document: T) => R
) => {
  const searchApi = useSDKApi(SearchApi);

  const getKey = useCallback(
    // TODO type return value
    (pageIndex: number, previousPageData: any) => {
      if (previousPageData && !previousPageData.hits?.length) return null;

      // SWR allows for cache keys to be full-blown objects
      // We're building our key based on the search parameters
      return {
        method: "search",
        organizationId,
        collection,
        ...searchParams,
        limit: searchParams.pageSize,
        offset: pageIndex * searchParams.pageSize,
      };
    },
    [organizationId, collection, searchParams]
  );

  const {
    data: pages,
    isLoading,
    size: numPages,
    setSize,
    mutate,
  } = useSWRInfinite(getKey, async (key) => {
    if (!organizationId) return;

    const res = await searchApi.search({
      searchRequest: {
        ...key,
        organizationId,
        collection: key.collection as SearchRequestCollectionEnum,
        filterBy: searchParams.filterBy?.map(
          (filterBy) =>
            ({
              ...filterBy,
              operator: filterBy.operator as FilterByOperator,
            }) as FilterBySpec
        ),
        sortBy: key.sortBy?.map((sortBy) => ({
          ...sortBy,
          direction: sortBy.direction as SortBySpecDirectionEnum,
        })),
      },
    });

    return {
      hits: res.result.hits.map((hit) => ({
        ...hit,
        document: transform
          ? transform(hit.document as T)
          : (hit.document as R),
      })),
    };
  });

  const hits = useMemo(() => {
    return pages ? flatten(pages.map((page) => page?.hits ?? [])) : undefined;
  }, [pages]);

  const loadMore = useCallback(() => {
    if (isLoading) return;
    if (hits?.length === searchParams.pageSize * numPages) {
      setSize((size) => size + 1);
    }
  }, [setSize, isLoading, hits, searchParams.pageSize, numPages]);

  return { data: hits ? { hits } : undefined, isLoading, loadMore, mutate };
};
