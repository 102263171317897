import { useMemo } from "react";
import { kebabCase } from "lodash";
import useSWR from "swr";

import { GenericError } from "@lib/api/errors/generic-error";
import fetchJSON from "@lib/fetch-json";

import { updateGroup } from "@components/Groups/utils";

const useOrgGroups = (oid: string) => {
  const { data } = useSWR(`/api/v1/users/${oid}/groups`, fetchJSON);

  const groups = useMemo(() => {
    if (!data) return;

    return data.map((group) => ({
      ...group,
      members: group.members.filter((member) => !member.removedAt),
    }));
  }, [data]);

  return {
    data: groups,
    loading: !data,
  };
};

export default function useGroups(oid: string | undefined): any {
  const { data: groups = [], loading: loadingGroups } = useOrgGroups(oid!);

  const now = new Date();

  const activeGroups = groups
    ?.filter((group) => group.status === "active")
    .map((group) => {
      const searchKey = kebabCase(group.title);
      return { ...group, searchKey };
    });

  const getGroup = (groupId: string) => groups.find(({ id }) => id === groupId);

  const getClientGroups = (id: string) => {
    const clientGroups = groups?.filter(({ members }) =>
      members.some((member) => member.clientId === id)
    );
    return clientGroups;
  };

  const handleGroupMember = (clientId: string, action: string) => {
    if (!oid) return;

    const clientGroups = getClientGroups(clientId);
    clientGroups?.forEach(async (group) => {
      const index = group.members.findIndex(
        (member) => member.clientId === clientId
      );
      group.members[index].removedAt = action === "archive" ? now : undefined;
      updateGroup(group.id, group, oid);
    });
  };

  const getMemberFormat = (clientId: string) => {
    return { clientId, addedAt: now };
  };

  const addOneMember = (clientId: string, groupId: string) => {
    if (!oid) return;

    const members = getGroup(groupId)?.members;
    members?.push(getMemberFormat(clientId));
    try {
      updateGroup(groupId, { members }, oid);
    } catch {
      throw new GenericError("Error adding new client to group");
    }
  };

  const removeOneMember = (clientId: string, groupId: string) => {
    if (!oid) return;

    const members = getGroup(groupId)?.members;
    const newMembers = members?.filter(
      (member) => member.clientId !== clientId
    );
    try {
      updateGroup(groupId, { members: newMembers }, oid);
    } catch {
      throw new GenericError("Error removing client from group");
    }
  };

  return {
    groups,
    activeGroups,
    getGroup,
    getClientGroups,
    handleGroupMember,
    addOneMember,
    removeOneMember,
    getMemberFormat,
    loading: loadingGroups,
  };
}
