import { FC } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";

import { getCycleFromDate } from "@lib/models/package-instances/utils";

interface PackageCyclePreviewProps {
  className?: string;
  packageInstance: PackageInstanceCard;
  targetDate: Date;
  showIfIsFull?: boolean;
}

const PackageCyclePreview: FC<PackageCyclePreviewProps> = ({
  className,
  packageInstance,
  targetDate,
  showIfIsFull = false,
}) => {
  const isRecurring = packageInstance?.packageType === "recurring";
  const cycle = getCycleFromDate(packageInstance, targetDate);
  if (!isRecurring) {
    return null;
  }

  if (!cycle) {
    return (
      <div className={classNames("text-sm", className)}>
        <p className="font-medium text-sm">Future cycle</p>
      </div>
    );
  }

  const cycleStart = moment(cycle.start).utc();
  const cycleEnd = moment(cycle.end).utc();
  return (
    <div>
      <div className={classNames("text-sm", className)}>
        <span className="font-medium">{cycle.title}:</span>{" "}
        <span className="text-grey-500">
          {cycleStart.format("MMM DD")} - {cycleEnd.format("MMM DD")}
        </span>
      </div>
      {showIfIsFull && cycle.isFull && (
        <p className="text-xs text-grey-500">Cycle is full</p>
      )}
    </div>
  );
};

export default PackageCyclePreview;
