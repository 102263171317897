import { useAuth } from "@contexts/auth";
import {
  applyUpdateToPageOfHits,
  useSWRCache,
  useUpdateAPICache,
} from "@hooks/use-update-swr-cache";
import { ClientType } from "@lib/data/schemas/client";

export type UpdateContactPayload = {
  contactId: string;
  data: Partial<ClientType>;
};

export const useUpdateContactsCache = () => {
  const { oid } = useAuth();

  const { updateCache } = useSWRCache();
  const { updateAPICache } = useUpdateAPICache();

  const updateContactsCache = async (updates: UpdateContactPayload[]) => {
    if (!oid) return;

    const formattedUpdates = updates.map((u) => ({
      id: u.contactId,
      data: u.data,
    }));

    for (const update of formattedUpdates) {
      await updateCache(
        (key) =>
          key.includes('method:"search"') &&
          key.includes('collection:"contacts"'),
        (key, cacheData) => {
          if (key.includes("$inf$")) {
            return cacheData.map((page) =>
              applyUpdateToPageOfHits(page, update, true)
            );
          }
          return cacheData;
        }
      );
    }

    const contactsQueryKey = `/api/v1/organizations/${oid}/contacts?`;
    await updateAPICache(contactsQueryKey, formattedUpdates);

    for (const update of formattedUpdates) {
      const contactQueryKey = `/api/v1/organizations/${oid}/contacts/${update.id}`;
      await updateAPICache(contactQueryKey, [update]);
    }
  };

  return {
    updateCache: updateContactsCache,
  };
};
